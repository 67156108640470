var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "mainBox" },
    [
      _c(
        "div",
        { staticClass: "router-view-wrap" },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.$store.state.catchPages } },
            [_c("router-view", { attrs: { navigation: _vm.navList } })],
            1
          ),
        ],
        1
      ),
      _c(
        "i-tabbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTabBar,
              expression: "showTabBar",
            },
          ],
          attrs: {
            "active-color": "var(--themeColor)",
            "inactive-color": "#13161B",
          },
          model: {
            value: _vm.navOn,
            callback: function ($$v) {
              _vm.navOn = $$v
            },
            expression: "navOn",
          },
        },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "i-tabbar-item",
            {
              key: index,
              attrs: { badge: item.count === 0 ? "" : item.count },
              nativeOn: {
                click: function ($event) {
                  return _vm.navigateTo(item, index)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function (props) {
                      return [
                        _c("i-icon", {
                          attrs: {
                            size: 22,
                            name: props.active ? item.iconOn : item.icon,
                            color: props.active ? "var(--themeColor)" : "",
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [_c("span", [_vm._v(_vm._s(item.txt))])]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }